export const apiUrl = {
  // ticket
  getAllTicket: 'ticket/doc/fetch-all',
  getTicketByTime: 'ticket/doc/fetch-time',
  createReservation: 'ticket/reservation/create',
  reserveReservation: 'ticket/reservation/reserve',
  releaseReservation: 'ticket/reservation/release',
  getSingleReservation: 'ticket/reservation/fetch',

  // order
  tentativePrice: 'order/price/tentative',
  getCustomFields: 'order/custom-field/fetch',
  createOrder: 'order/create',
  createPayment: 'order/payment/create',
  getOrderSummary: 'order/fetch/summary',
  generateOdcLink: 'odc/link/generate',
  validateOdcLink: 'odc/data/is-valid/link',

  // payment
  startPayment: 'payment/start',
  getPaymentStatus: 'payment/fetch-status',

  // show
  getShowDetail: 'show/fetch',
  getShowFilter: 'shows/filter',
  getHighlightShow: 'shows/highlight',
  getTopBanner: 'shows/top-banner',
  searchShowByKeyword: 'shows/suggestion',
  getEventCategory: 'show/event-category/fetch',
  getListCity: 'show/city/fetch/all',
};
