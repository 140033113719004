import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCommon } from '../../dto/error-common';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _notification: NzNotificationService) {}

  showSuccessNotification(message: string): void {
    this._notification.create('success', 'Thành công', message, {
      nzDuration: 3000,
      nzPlacement: 'topRight',
    });
  }

  showErrorNotification(message: string): void {
    this._notification.create('error', 'Thất bại', message, {
      nzDuration: 3000,
      nzPlacement: 'topRight',
    });
  }

  showHttpErrorNotification(err: HttpErrorResponse): void {
    const error = err.error as ErrorCommon;
    this._notification.create('error', 'Thất bại', error.message, {
      nzDuration: 3000,
      nzPlacement: 'topRight',
    });
  }

  showWarningNotification(message: string): void {
    this._notification.create('warning', 'Thông báo', message, {
      nzDuration: 3000,
      nzPlacement: 'topRight',
    });
  }
}
