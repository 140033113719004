import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import { routeName } from 'src/app/lib/constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private _router: Router,
    private _location: Location,
  ) {}

  navigateTo(route: string, extras?: NavigationExtras): void {
    this._router.navigate([route], extras).then(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  }

  navigateToHome(): void {
    this.navigateTo(routeName.root);
  }

  navigateToNotFound(): void {
    this.navigateTo(routeName.notFound, { replaceUrl: true });
  }

  goBack(): void {
    this._location.back();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
}
