import { Injectable } from '@angular/core';
import { HttpClientService } from '../http-client/http-client.service';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  CustomFieldResponse,
  GenerateOdcLinkRequest,
  GetCustomFieldsRequest,
  OrderSummaryResponse,
  TentativePriceRequest,
  TentativePriceResponse,
} from 'src/app/lib/entities';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/app/lib/constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private _http: HttpClientService) {}

  tentativePrice(body: TentativePriceRequest): Observable<TentativePriceResponse> {
    if (body.tickets.length === 0) {
      return new Observable(function subscribe(observer) {
        const tentativePrice: TentativePriceResponse = {
          priceTotal: 0,
          priceDiscount: 0,
          shipPrice: 0,
        };

        observer.next(tentativePrice);
        observer.complete();
      });
    }

    return this._http.post<TentativePriceResponse>(apiUrl.tentativePrice, body);
  }

  getCustomFields(body: GetCustomFieldsRequest): Observable<CustomFieldResponse[]> {
    return this._http.post<CustomFieldResponse[]>(apiUrl.getCustomFields, body);
  }

  generateOdcLink(body: GenerateOdcLinkRequest): Observable<string> {
    return this._http.postAsText(apiUrl.generateOdcLink, body);
  }

  validateOdcLink(body: string): Observable<string> {
    return this._http.postAsText(apiUrl.validateOdcLink, body);
  }

  createOrder(body: CreateOrderRequest): Observable<CreateOrderResponse> {
    return this._http.post<CreateOrderResponse>(apiUrl.createOrder, body);
  }

  createPayment(body: CreatePaymentRequest): Observable<CreatePaymentResponse> {
    return this._http.post<CreatePaymentResponse>(apiUrl.createPayment, body);
  }

  getOrderSummary(orderId: string): Observable<OrderSummaryResponse> {
    const params = new HttpParams().set('id', orderId);
    return this._http.get<OrderSummaryResponse>(apiUrl.getOrderSummary, params);
  }
}
