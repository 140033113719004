import { Injectable } from '@angular/core';
import { BookingState } from 'src/app/lib/stores';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  saveData(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getData<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (value != null) {
      return JSON.parse(value) as T;
    }

    return null;
  }

  removeData(key: string): void {
    localStorage.removeItem(key);
  }

  saveBookingSession(data: BookingState): void {
    const key = `${data.showId}-${data.sessionId}`;
    this.saveData(key, data);
  }

  getBookingSession(showId: string, sessionId: string): BookingState | null {
    const key = `${showId}-${sessionId}`;
    return this.getData<BookingState>(key);
  }

  removeBookingSession(showId: string, sessionId: string): void {
    const key = `${showId}-${sessionId}`;
    this.removeData(key);
  }
}
