import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/lib/services/http-client/http-client.service';
import {
  CreateReservationRequest,
  CreateReservationResponse,
  GetAllTicketRequest,
  GetSingleReservationResponse,
  GetTicketByTimeRequest,
  TicketResponse,
} from 'src/app/lib/entities';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/app/lib/constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private _http: HttpClientService) {}

  getAllTicket(body: GetAllTicketRequest): Observable<TicketResponse[]> {
    return this._http.post<TicketResponse[]>(apiUrl.getAllTicket, body);
  }

  getTicketByTime(body: GetTicketByTimeRequest): Observable<TicketResponse[]> {
    return this._http.post<TicketResponse[]>(apiUrl.getTicketByTime, body);
  }

  createReservation(body: CreateReservationRequest): Observable<CreateReservationResponse> {
    return this._http.post<CreateReservationResponse>(apiUrl.createReservation, body);
  }

  reserveReservation(reservationId: string): Observable<any> {
    const params = new HttpParams().set('id', reservationId);
    return this._http.get(apiUrl.reserveReservation, params);
  }

  getSingleReservation(reservationId: string): Observable<GetSingleReservationResponse> {
    const params = new HttpParams().set('id', reservationId);
    return this._http.get<GetSingleReservationResponse>(apiUrl.getSingleReservation, params);
  }

  releaseReservation(reservationId: string): Observable<any> {
    const params = new HttpParams().set('id', reservationId);
    return this._http.get(apiUrl.releaseReservation, params);
  }
}
