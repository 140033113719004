export const routeName = {
  root: '',
  event: 'su-kien',
  faq: 'faq',
  show: 'show',
  login: 'dang-nhap',
  register: 'dang-ky',
  contact: 'lien-he',
  aboutUs: 've-chung-toi',
  booking: 'dat-ve',
  policyRegulation: 'quy-dinh-chung',
  policyInformationSecurity: 'chinh-sach-bao-mat',
  notFound: 'not-found',
};
