import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(private _http: HttpClient) {}

  private _metaDomain = environment.metaDomain;
  private _metaPrefix = environment.metaPrefix;
  private _searchDomain = environment.searchDomain;

  getFromNobi<T>(path: string, params?: HttpParams): Observable<T> {
    const url = `${this._searchDomain}/${path}`;
    return this._http.get<T>(url, { params });
  }

  postFromNobi<T>(path: string, body: any, params?: HttpParams): Observable<T> {
    const url = `${this._searchDomain}/${path}`;
    return this._http.post<T>(url, body, { params });
  }

  get<T>(path: string, params?: HttpParams): Observable<T> {
    const url = `${this._metaDomain}/${this._metaPrefix}/${path}`;
    return this._http.get<T>(url, { params });
  }

  post<T>(path: string, body: any, params?: HttpParams): Observable<T> {
    const url = `${this._metaDomain}/${this._metaPrefix}/${path}`;
    return this._http.post<T>(url, body, { params });
  }

  postAsText(path: string, body: any, params?: HttpParams): Observable<string> {
    const url = `${this._metaDomain}/${this._metaPrefix}/${path}`;
    return this._http.post(url, body, { params, responseType: 'text' });
  }

  put<T>(path: string, body: any, params?: HttpParams): Observable<T> {
    const url = `${this._metaDomain}/${this._metaPrefix}/${path}`;

    return this._http.put<T>(url, body, { params });
  }

  delete<T>(path: string, params?: HttpParams): Observable<T> {
    const url = `${this._metaDomain}/${this._metaPrefix}/${path}`;

    return this._http.delete<T>(url, { params });
  }
}
