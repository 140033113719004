import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/app/lib/constants';
import {
  CityResponse,
  EventCategoryResponse,
  GetHighlightShowResponse,
  HighlightShowRequest,
  SearchShowFilter,
  SearchShowResponse,
  ShowResponse,
} from 'src/app/lib/entities';
import { HttpClientService } from '../http-client/http-client.service';

@Injectable({ providedIn: 'root' })
export class ShowService {
  constructor(private _httpClientService: HttpClientService) {}

  getShowDetail(showId: string): Observable<ShowResponse> {
    const param = new HttpParams().set('id', showId);
    return this._httpClientService.get<ShowResponse>(apiUrl.getShowDetail, param);
  }

  searchShowByFilter(body: SearchShowFilter): Observable<GetHighlightShowResponse> {
    let params = new HttpParams().set('page', body.page).set('size', body.size);
    if ('isFree' in body) {
      params = params.set(`isFree`, body.isFree ?? false);
    }
    if (body.eventCategoryCode) {
      params = params.set(`eventCategoryCode`, body.eventCategoryCode);
    }
    if (body.startTime) {
      params = params.set(`startTime`, body.startTime);
    }
    if (body.endTime) {
      params = params.set(`endTime`, body.endTime);
    }
    if (body.cities && body.cities.length > 0) {
      body.cities.forEach((city) => {
        params = params.append(`cities`, city);
      });
    }
    return this._httpClientService.getFromNobi<GetHighlightShowResponse>(apiUrl.getShowFilter, params);
  }

  getHighlightShow(body: HighlightShowRequest): Observable<GetHighlightShowResponse> {
    let params = new HttpParams().set('page', body.page).set('size', body.size);
    if (body.cities && body.cities.length > 0) {
      body.cities.forEach((city) => {
        params = params.append(`cities`, city);
      });
    }
    return this._httpClientService.getFromNobi<GetHighlightShowResponse>(apiUrl.getHighlightShow, params);
  }

  getTopBannerShow(): Observable<GetHighlightShowResponse> {
    return this._httpClientService.getFromNobi<GetHighlightShowResponse>(apiUrl.getTopBanner);
  }

  searchShowByKeyword(param: { keyword: string; page: number; size: number }): Observable<GetHighlightShowResponse> {
    const params = new HttpParams().set('page', param.page).set('size', param.size).set('keyword', param.keyword);
    return this._httpClientService.getFromNobi<GetHighlightShowResponse>(apiUrl.searchShowByKeyword, params);
  }

  getEventCategory(): Observable<EventCategoryResponse[]> {
    return this._httpClientService.get<EventCategoryResponse[]>(apiUrl.getEventCategory);
  }

  getListCity(): Observable<CityResponse[]> {
    return this._httpClientService.get<CityResponse[]>(apiUrl.getListCity);
  }

  saveToLocalStorage(show: SearchShowResponse): void {
    const shows = localStorage.getItem('shows') || '[]';
    const showsJson = JSON.parse(shows) as SearchShowResponse[];
    const index = showsJson.findIndex((item: SearchShowResponse) => item.id === show.id);
    if (index != -1) {
      showsJson.splice(index, 1);
    }
    showsJson.unshift(show);
    localStorage.setItem('shows', JSON.stringify(showsJson));
  }

  getShowsFromLocalStorage(): SearchShowResponse[] {
    const shows = localStorage.getItem('shows');
    if (shows) {
      return JSON.parse(shows) as SearchShowResponse[];
    }
    return [];
  }
}
